@use 'libs/styles/variables';
@use 'libs/styles/layout';

.github {
  @include layout.row;
  @include variables.paragraph;
  border-radius: variables.$border-radius-xxl;
  padding: 6px 8px;
  padding-right: 12px;
  font-weight: 500;
  color: variables.$color-fund;
  background: rgba(variables.$color-fund, 0.2);
  transition: variables.$transition-s;

  &:hover {
    background: rgba(variables.$color-fund, 0.3);
  }

  &.purple {
    color: variables.$color-purple;
    background: rgba(variables.$color-purple, 0.2);

    &:hover {
      background: rgba(variables.$color-purple, 0.3);
    }
  }

  &.blue {
    color: variables.$color-org;
    background: rgba(variables.$color-org, 0.2);

    &:hover {
      background: rgba(variables.$color-org, 0.3);
    }
  }

  &.shrink {
    @media (max-width: variables.$device-md) {
      padding-right: 8px;

      span {
        display: none;
      }
    }
  }
}
