@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';

.container {
  @include utils.increase-specificity(2) {
    @include layout.row;
    position: relative;
    justify-content: flex-start;
    width: 520px;
    height: 110px;
    margin-left: 12px;
    border-radius: 24px;
    padding: 12px 20px;

    & > :first-child {
      flex-grow: 1;
    }

    a {
      display: flex;
      transition: variables.$transition-s;

      &:hover {
        transform: translateY(-8px);
      }
    }

    @media (max-width: variables.$device-md) {
      @include layout.row;
      flex-wrap: wrap;
      width: fit-content;
      height: fit-content;
      margin: auto;
      padding: 12px 16px;

      & > :first-child {
        @include layout.row;
        justify-content: space-between;

        hr {
          display: none;
        }

        img {
          width: 140px;
        }
      }
    }
  }
}

.text {
  @include layout.column;
  align-items: flex-start;
  color: variables.$color-slate;

  @media (max-width: variables.$device-md) {
    @include utils.increase-specificity(3) {
      display: none;
    }
  }

  span {
    width: 184px;
    border-top: 1px solid rgba(variables.$color-purple, 0.1);
    padding: 8px 8px 0;
  }

  & > b {
    font-weight: 700;
  }
}

.version {
  @include variables.label;
  margin-left: 9px;
  border-radius: variables.$border-radius-s;
  padding: 2px 5px;
  font-weight: 700;
  color: variables.$color-white;
  background-color: rgba(variables.$color-purple, 0.75);
}

.icon {
  position: absolute;
  top: -16px;
  right: -150px;
  width: 100px;
  height: 100px;
  transform: rotateZ(15deg);
  filter: variables.$token-shadow;
  will-change: filter;

  @media (max-width: variables.$device-md) {
    display: none;
  }
}
