@use 'libs/styles/variables';
@use 'libs/styles/layout';

$color-gray: #5a78a3;

.documentation {
  @include layout.row;
  @include variables.paragraph;
  border-radius: variables.$border-radius-xxl;
  padding: 6px 8px;
  padding-right: 12px;
  font-weight: 500;
  color: $color-gray;
  background: rgba($color-gray, 0.2);
  transition: variables.$transition-s;

  &:hover {
    background: rgba($color-gray, 0.3);
  }

  &.shrink {
    @media (max-width: variables.$device-md) {
      padding-right: 8px;

      span {
        display: none;
      }
    }
  }
}
