@use 'libs/styles/variables';
@use 'libs/styles/layout';

.wrapper {
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(variables.$color-purple, 0.25);
}

.container {
  @include layout.max-width;
  @include layout.row;
  position: relative;
  height: 400px;
  margin-bottom: 80px;

  @media (max-width: variables.$device-md) {
    @include layout.column;
    height: 100%;
  }
}

.text {
  @include layout.column;
  position: relative;
  z-index: 1;
  width: 600px;
  margin-top: 148px;
  margin-left: 25%;

  @media (max-width: variables.$device-md) {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 24px;
    margin-left: 0;
  }

  h2 {
    @include variables.title(2);
    margin-bottom: 16px;
    margin-left: 38px;
    font-weight: 700;
    font-family: 'GT Haptik', sans-serif;
    color: variables.$color-dark-light;

    @media (max-width: variables.$device-md) {
      margin-bottom: 12px;
      margin-left: 0;
      text-align: center;
    }
  }
}

.try {
  position: absolute;
  top: 80px;
  left: 50%;
  z-index: 0;
  transform: translateX(-110px);

  @media (max-width: variables.$device-md) {
    display: none;
  }

  & > :first-child {
    position: absolute;
    top: -2px;
    left: 0;
    transform: translateX(-100%);
  }

  path {
    animation: variables.$dash-animation--reverse;
  }
}

.ipad-dude {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 4;
  object-fit: contain;
  transform: translateX(-500px);
  filter: variables.$token-shadow;
  will-change: filter;

  @media (max-width: variables.$device-md) {
    top: 236px;
    right: 12px;
    left: unset;
    width: 160px;
    height: 300px;
    transform: scaleX(-1);
  }
}

.cta {
  @include variables.paragraph(true);
  padding: 12px 0 0 38px;
  color: variables.$color-disabled;

  a {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    color: variables.$color-purple;

    svg {
      width: 20px;
    }
  }

  @media (max-width: variables.$device-md) {
    padding: 12px 0 0;
    text-align: center;
  }
}
