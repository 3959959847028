@use 'libs/styles/variables';
@use 'libs/styles/layout';
@use 'libs/styles/utils';

.hero {
  &__content {
    position: relative;
    width: 100%;
    height: 740px;

    @media (max-width: variables.$device-md) {
      height: 720px;
    }
  }

  &__container {
    position: relative;
    width: 100%;

    @media (max-width: variables.$device-md) {
      height: 700px;

      .rocket,
      .person,
      .folder {
        display: none;
      }
    }
  }
}

.feature-list {
  @include layout.column;

  @include utils.increase-specificity(2) {
    border-color: rgba(variables.$color-purple, 0.25);
  }
  position: absolute;
  top: 360px;
  left: 50%;
  z-index: 5;
  width: 660px;
  border-radius: 32px;
  padding: 12px 24px 10px;
  list-style: none;
  transform: translateX(-145px);

  & > li > a {
    @include layout.row(1.5rem);
    @include variables.paragraph;
    justify-content: flex-start;
    width: 100%;

    @media (max-width: variables.$device-md) {
      gap: 1rem;
    }

    & > img {
      @media (max-width: variables.$device-md) {
        width: 52px;
        height: 52px;
      }
    }

    p {
      border-bottom: 1px solid rgba(variables.$color-purple, 0.2);
      padding: 22px 24px 26px 0;
      font-size: 15px;
      color: variables.$color-dark-light;
      transition: variables.$transition-s;

      b {
        color: variables.$color-fund;
      }

      [class*='Pill'] {
        padding: 0.125rem 0.5rem;
        font-size: 12px;
        font-weight: 600;
        color: white;
        background: variables.$color-fund;
      }

      &.new-feature {
        @include layout.row;
      }

      @media (max-width: variables.$device-md) {
        padding: 12px 0 16px 4px;
        font-size: 12px;
      }
    }

    & > :last-child {
      @media (max-width: variables.$device-md) {
        display: none;
      }
    }

    &,
    & > img {
      transition: variables.$transition-s;
    }

    &:hover {
      transform: translateY(-6px);

      img {
        transform: rotate(-8deg);
      }

      &.link--sdk {
        p {
          border-bottom: 1px solid rgba(variables.$color-fund, 0.5);
          color: variables.$color-fund;
        }

        & svg {
          color: variables.$color-fund;
        }
      }

      &.link--api {
        p {
          border-bottom: 1px solid rgba(variables.$color-purple, 0.5);
          color: variables.$color-purple;
        }

        & svg {
          color: variables.$color-purple;
        }
      }

      &.link--graph {
        p {
          color: variables.$color-org;
        }

        & svg {
          color: variables.$color-org;
        }
      }
    }

    svg {
      flex: 20px;
      color: rgba(variables.$color-purple, 0.75);
      opacity: 0.5;

      path {
        stroke: currentcolor;
      }
    }
  }

  & > li:last-child > a > p {
    border-bottom: none;
  }

  @media (max-width: variables.$device-md) {
    top: 300px;
    left: 24px;
    width: calc(100% - 48px);
    transform: none;
  }
}

.folder {
  top: 276px;
  width: 80px;
  height: 80px;
  margin-left: 32px;
  transform: translateX(206px);
}

.sponsors {
  @include layout.column(0);
  position: relative;
  z-index: 5;
  align-items: center;
  width: 100%;
  margin-top: 0;
  border-top: 1px solid rgba(variables.$color-purple, 0.25);
  border-bottom: 1px solid rgba(variables.$color-purple, 0.25);
  padding: 24px 0;
  background-color: #fbfdff;

  @media (max-width: variables.$device-md) {
    margin-top: -65px;
  }

  &__label {
    @include variables.paragraph;
    height: 28px;
    margin-top: -38px;
    margin-bottom: 14px;
    border: 1px solid variables.$color-lighter;
    border-radius: variables.$border-radius-m;
    padding: 4px 14px;
    font-weight: 500;
    color: rgba(variables.$color-purple, 0.8);
    background-color: variables.$color-white;

    @media (max-width: variables.$device-md) {
      margin-top: -40px;
    }
  }

  ul {
    @include layout.row(4rem);
    max-width: 100%;
    padding-top: 0.5rem;
    overflow: hidden;
    list-style: none;

    li {
      pointer-events: none;

      img {
        width: auto;
        max-width: 150px;
        height: 60px;
        object-fit: contain;
        object-position: center;
        opacity: 0.8;
        filter: sepia(100%) hue-rotate(180deg) saturate(0.8);
      }
    }
  }
}

.lines-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  svg {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    object-fit: cover;
    transform: translateX(-50%);

    path {
      animation: variables.$dash-animation;
    }
  }
}

.developers-info {
  position: absolute;
  top: 150px;
  left: 50%;
  width: 580px;
  transform: translateX(-180px);

  h3 {
    @include variables.title(2, false);

    @include utils.increase-specificity(2) {
      margin-bottom: 14px;
      font-size: 40px;
    }
    max-width: 520px;
    padding: 0 18px;
    font-family: 'GT Haptik', sans-serif;
    line-height: 95%;
    color: rgba(variables.$color-logo-blue, 0.8);
  }

  h5 {
    @include variables.header(4);
    max-width: 480px;
    padding: 0 18px;
    font-size: 22px;
    line-height: 120%;
    color: variables.$color-purple;
  }

  .developers-cta {
    width: 100%;
  }

  @media (max-width: variables.$device-md) {
    top: 130px;
    left: 24px;
    width: 280px;
    transform: none;

    h3 {
      @include utils.increase-specificity(2) {
        max-width: 100%;
        margin-bottom: 16px;
        font-size: 28px;
      }
    }

    h5 {
      max-width: 300px;
    }

    .developers-cta > div {
      flex-shrink: 0;
    }
  }
}

.person,
.rocket,
.smiley,
.folder {
  position: absolute;
  left: 50%;
  z-index: 3;

  img {
    object-fit: contain;
  }

  // filter: variables.$token-shadow;
  // will-change: filter;
}

.person {
  top: 20px;
  transform: translateX(-640px);
}

.smiley {
  top: 36px;
  transform: translateX(-130px);
}

.rocket {
  top: 630px;
  transform: translateX(112px);
}
